import React, { useState, useEffect } from 'react';
import config from "../config/config";
import '../styles/LoginHistoryTable.css';

const LoginHistoryTable = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    userId: '',
    startDate: '',
    endDate: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [expandedRow, setExpandedRow] = useState(null); // 展開中の行

  const fetchHistory = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const queryParams = new URLSearchParams({
        ...filters,
        page: currentPage,
        limit: itemsPerPage,
      }).toString();

      const response = await fetch(`${config.apiBaseUrl}/login-history?${queryParams}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      if (!response.ok) throw new Error('Failed to fetch login history');
      const data = await response.json();
      setHistory(data);
    } catch (error) {
      console.error('Error fetching login history:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHistory();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (direction) => {
    if (direction === 'next') {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleExpandRow = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId); // 展開中の行を切り替え
  };

  return (
    <div className='LoginHistory'>
      <h2>Login History</h2>
      <div>
        <label>User ID:</label>
        <input type="text" name="userId" className='idinput' value={filters.userId} onChange={(e) => setFilters({ ...filters, userId: e.target.value })} />

        <label>Start Date:</label>
        <input type="date" name="startDate" value={filters.startDate} onChange={(e) => setFilters({ ...filters, startDate: e.target.value })} />

        <label>End Date:</label>
        <input type="date" name="endDate" value={filters.endDate} onChange={(e) => setFilters({ ...filters, endDate: e.target.value })} />

        <button onClick={fetchHistory}>Search</button>
      </div>
      <div style={{ margin: '10px 0' }}>
        <label>1ページあたりの表示件数:</label>
        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>User ID</th>
                <th>Username</th>
                <th>Login Time</th>
                <th>IP Address</th>
                <th>User Agent</th>
              </tr>
            </thead>
            <tbody>
              {history.map((entry) => (
                <React.Fragment key={entry.id}>
                  <tr>
                    <td>{entry.id}</td>
                    <td>{entry.user_id}</td>
                    <td>{entry.username || 'Unknown'}</td>
                    <td>{new Date(entry.login_time).toLocaleString()}</td>
                    <td>{entry.ip_address}</td>
                    <td>
                      <button onClick={() => handleExpandRow(entry.id)}>
                        {expandedRow === entry.id ? '折りたたむ' : '展開'}
                      </button>
                    </td>
                  </tr>
                  {expandedRow === entry.id && (
                    <tr>
                      <td colSpan="6">
                        <pre style={{ background: '#f4f4f4', padding: '10px', borderRadius: '5px', overflowX: 'auto' }}>
                          {entry.user_agent}
                        </pre>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div>
            <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>
              前へ
            </button>
            <span>ページ: {currentPage}</span>
            <button onClick={() => handlePageChange('next')}>次へ</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginHistoryTable;
