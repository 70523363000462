import React, { useState, useEffect } from 'react';
import '../styles/ShiftModal.css';
import { checkResponseStatus } from '../utils/tokenExpiredHandler';
import config from "../config/config";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja'; // 日本語ロケールをインポート
import { format } from 'date-fns';
registerLocale('ja', {
  ...ja,
  localize: {
    ...ja.localize,
    month: (n) => `${n + 1}`, // 月を数字表記にカスタマイズ
  },
});

const ShiftModal = ({
  isOpen,
  selectedCell,
  onConfirm,
  onCancel,
  shiftData,
  setShiftData,
  shifts,
  employees,
  currentUser,
  userList,
  copiedShift,
  setCopiedShift,
  shift, onClose, onSave
}) => {
  const [shiftCode, setShiftCode] = useState("");
  const [isTemporary, setIsTemporary] = useState(false);
  const [status, setStatus] = useState("未承認");
  const [note, setNote] = useState("");
  const [timeAdjustment, setTimeAdjustment] = useState(0);
  const [shiftColor, setShiftColor] = useState("#ffffff");
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [lockInfo, setLockInfo] = useState(null);
  const [isSecondColorEnabled, setIsSecondColorEnabled] = useState(false);
  const [isColor, setIsColor] = useState(false)
  const [secoundShiftCode, setSecoundShiftCode] = useState("");


  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      const formatted = `${String(hour).padStart(2, '0')}:00`; // 分を固定
      options.push(formatted);
    }
    return options;
  };

  useEffect(() => {
    if (isOpen && selectedCell) {
      console.log('Modal opened with selectedCell:', selectedCell);
      if (currentUser?.is_editable === 0) {
        setCanEdit(false);
        setLockInfo(null); // ロック情報をリセット
        return; // ここで終了し、ロック判定をスキップ
      }

      if (selectedCell.lockInfo) {
        console.log('Cell is locked by another user:', selectedCell.lockInfo);
        setCanEdit(false); // 編集不可
        setLockInfo(selectedCell.lockInfo);
      } else {
        console.log('Cell is editable.');
        setCanEdit(true); // 編集可能
      }

      const cellKey = `${employees[selectedCell.row].id}-${selectedCell.date}`;
      const cellData = shiftData[cellKey] || {};
      setShiftCode(cellData.shift_code || "朝");
      setSecoundShiftCode(cellData.shift_code2 || "");
      setIsTemporary(cellData.is_temporary || false);
      setStatus(cellData.status || "未承認");
      setNote(cellData.note || "");
      setTimeAdjustment(cellData.time_adjustment || 0);
      setShiftColor(cellData.shift_color || "#66ffff");
      setShiftColor2(cellData.shift_color2 || "#000000")
      setStartTime(cellData.temp_start || "00:00");
      setEndTime(cellData.temp_end || "00:00");
      console.log('Initial shift data:', shiftData[selectedCell.row]);

      console.log('Modal opened with initial values:', {
        temp_start: cellData.temp_start,
        temp_end: cellData.temp_end,
      });
    }

  }, [isOpen, selectedCell, shiftData, employees]);

  const lockedByName = lockInfo?.lockedBy ? userList[lockInfo.lockedBy] || '不明なユーザー' : '';

  const handleSave = async () => {
    const cellKey = `${employees[selectedCell.row].id}-${selectedCell.date}`;
    const existingShift = shiftData[cellKey];
    const isExistingShift = !!existingShift?.id;

    // 日付を+1日補正
    const originalDate = new Date(selectedCell.date);
    const adjustedDate = new Date(originalDate);
    adjustedDate.setDate(originalDate.getDate() + 1); // +1日する

    // 日付をフォーマットして登録
    // const formattedDate = adjustedDate.toISOString().split('T')[0];
    const formattedDate = `${adjustedDate.getFullYear()}-${String(adjustedDate.getMonth() + 1).padStart(2, '0')}-${String(adjustedDate.getDate()).padStart(2, '0')}`;

    // 既存の最大IDを探し、そのID + 1を新しいIDとする
    const maxId = Math.max(0, ...Object.values(shiftData).map(shift => shift.id || 0));
    const newId = isExistingShift ? existingShift.id : maxId + 1;

    const newShift = {
      id: newId,
      staff_id: employees[selectedCell.row].id,
      date: formattedDate, // 修正済みの日付
      shift_code: shiftCode,
      shift_code2: secoundShiftCode,
      is_temporary: isTemporary,
      temp_start: isTemporary ? startTime : null,
      temp_end: isTemporary ? endTime : null,
      status: status,
      note: note,
      time_adjustment: timeAdjustment,
      shift_color: shiftColor,
      shift_color2: isSecondColorEnabled ? shiftColor2 : null,
    };

    setShiftData((prevShiftData) => ({
      ...prevShiftData,
      [cellKey]: newShift,
    }));

    // リクエスト送信部分
    try {
      const token = localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const url = isExistingShift
        ? `${config.apiBaseUrl}/shiftData/${newShift.id}`
        : `${config.apiBaseUrl}/shiftData`;
      const method = isExistingShift ? 'PUT' : 'POST';

      const res = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(newShift),
      });
      if (!(await checkResponseStatus(res))) return;
      if (!res.ok) {
        console.error(`シフトの${method === 'PUT' ? '更新' : '追加'}に失敗しました`);
      }
    } catch (error) {
      console.error('更新リクエストエラー:', error);
    }

    onConfirm();
  };

  const deleteShift = async () => {
    const cellKey = `${employees[selectedCell.row].id}-${selectedCell.date}`;
    const shiftToDelete = shiftData[cellKey];

    try {
      const token = localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      // DBのエンドポイントを指定して削除リクエスト
      const res = await fetch(`${config.apiBaseUrl}/shiftData/${shiftToDelete.id}`, {
        method: 'DELETE',
        headers
      });
      if (!(await checkResponseStatus(res))) return;
      if (!res.ok) {
        console.error('シフトの削除に失敗しました');
        return;
      }

      // フロント側のデータも更新
      setShiftData((prev) => {
        const updatedData = { ...prev };
        delete updatedData[cellKey];
        return updatedData;
      });

      // モーダルを閉じる
      handleCloseDeleteModal();
      onCancel();
    } catch (error) {
      console.error('削除リクエストエラー:', error);
    }
  };


  const handleShiftCodeChange = (e) => {
    const newShiftCode = e.target.value;
    setShiftCode(newShiftCode);

    // 該当のシフトコードのデフォルト色を取得して設定
    const selectedShift = shifts.find((shift) => shift.shift_code === newShiftCode);
    if (selectedShift) {
      setShiftColor(selectedShift.default_color || '#ffffff');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // 文字列をDateオブジェクトに変換
    const month = date.getMonth() + 1; // 月（0ベースなので+1）
    const day = date.getDate();       // 日
    return `${month}月${day}日`;      // フォーマット済みの日付を返す
  };

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const formatTime = (time) => {
    if (!time) return "00:00"; // デフォルト値
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}`; // "hh:mm"形式に変換
  };

  const handleSecondColorToggle = () => {
    setIsSecondColorEnabled(!isSecondColorEnabled);
    if (!isSecondColorEnabled) setShiftColor2(null); // OFFならnullにする
  };

  const handleColorChange2 = (e) => setShiftColor2(e.target.value);


  const [shiftCode1, setShiftCode1] = useState("朝"); // シフトカラー1の選択
  const [shiftColor1, setShiftColor1] = useState("#42ffff"); // シフトカラー1の色

  const [shiftCode2, setShiftCode2] = useState("朝"); // シフトカラー2の選択
  const [shiftColor2, setShiftColor2] = useState("#42ffff"); // シフトカラー2の色

  const [selectColor1, setSelectColor1] = useState("朝");
  const [selectColor2, setSelectColor2] = useState("朝");

  const defaultColors = {
    "朝": "#42ffff",
    "昼": "#ffff00",
    "夕": "#da9694",
    "夜": "#cc99ff",
    "福生": "#92d050",
    "任意": "#ffffff"
  };

  useEffect(() => {
    if (isOpen) {
      // シフトカラー1の初期値設定
      const color1Key = Object.keys(defaultColors).find(key => defaultColors[key] === shiftColor);
      setSelectColor1(color1Key || "任意");

      // シフトカラー2の初期値設定
      const color2Key = Object.keys(defaultColors).find(key => defaultColors[key] === shiftColor2);
      setSelectColor2(color2Key || "任意");
    }
  }, [isOpen, shiftColor, shiftColor2]);


  //コピー・貼り付け
  const handleCopy = () => {
    const cellKey = `${employees[selectedCell.row].id}-${selectedCell.date}`;
    const shiftToCopy = shiftData[cellKey];

    if (shiftToCopy) {
      setCopiedShift(shiftToCopy); // 親の状態を更新
      console.log('Copied shift:', shiftToCopy);
      onCancel();
    } else {
      console.warn('No shift data to copy');
    }
  };

  const handlePaste = async () => {
    if (!copiedShift) {
      console.warn('No shift data to paste');
      return;
    }
  
    const cellKey = `${employees[selectedCell.row].id}-${selectedCell.date}`;
    const { id, ...restOfShift } = copiedShift; // idを除外
  
    const existingShift = shiftData[cellKey]; // 既存データをチェック
  
    // +1日調整を行う
    const originalDate = new Date(selectedCell.date);
    originalDate.setDate(originalDate.getDate() + 1); // +1日
  
    const newShift = {
      ...restOfShift,
      staff_id: employees[selectedCell.row].id,
      date: originalDate.toISOString().split('T')[0], // 調整後の日付
    };
  
    // フロントエンドデータの更新
    setShiftData((prevShiftData) => ({
      ...prevShiftData,
      [cellKey]: { ...newShift, id: existingShift?.id || null }, // 既存IDをセット
    }));
  
    // サーバー側へのリクエスト
    try {
      const token = localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
  
      const url = existingShift
        ? `${config.apiBaseUrl}/shiftData/${existingShift.id}` // 更新用
        : `${config.apiBaseUrl}/shiftData`;                   // 新規用
      const method = existingShift ? 'PUT' : 'POST';
  
      const res = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(newShift),
      });
      if (!(await checkResponseStatus(res))) return;
      if (!res.ok) {
        console.error(`Failed to ${method === 'PUT' ? 'update' : 'create'} shift`);
        return;
      }
  
      console.log('Pasted shift:', newShift);
    } catch (error) {
      console.error('Error during paste operation:', error);
    }
  
    onConfirm(); // モーダルを閉じる
  };
  


  //一括登録
  const [showBulkRegister, setShowBulkRegister] = useState(false); // 一括登録モードの状態
  const [bulkDates, setBulkDates] = useState([]);

  useEffect(() => {
    if (showBulkRegister && selectedCell) {
      // カレンダーを開いたときに初期選択日を設定
      const initialDate = new Date(selectedCell.date); // selectedCell.data を日付として使用
      setBulkDates([initialDate]);
    }
  }, [showBulkRegister, selectedCell]);

  const toggleDateSelection = (date) => {
    // const dateString = date.toISOString().split('T')[0];
    const dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    if (bulkDates.some((d) => d.toISOString().split('T')[0] === dateString)) {
      setBulkDates(bulkDates.filter((d) => d.toISOString().split('T')[0] !== dateString));
    } else {
      setBulkDates([...bulkDates, date]);
    }
  };

  const handleBulkRegister = async () => {
    if (!bulkDates.length) {
      alert('日付を選択してください！');
      return;
    }
  
    const staffId = employees[selectedCell.row]?.id;
  
    const requestBody = {
      staffId,
      dates: bulkDates.map((date) => {
        const adjustedDate = new Date(date);
        adjustedDate.setDate(adjustedDate.getDate() + 1); // 日付補正
        return adjustedDate.toISOString().split('T')[0];
      }),
      shift: {
        shiftCode,
        shiftCode2: secoundShiftCode || null, // 副シフトコードが未入力の場合は null
        isTemporary,
        tempStart: isTemporary ? startTime : null,
        tempEnd: isTemporary ? endTime : null,
        status,
        note,
        timeAdjustment,
        shiftColor,
        shiftColor2: shiftColor2 !== '#000000' ? shiftColor2 : null, // 未入力時は null に設定
      },
    };
  
    console.log('Bulk Registration Request:', requestBody); // リクエスト内容を確認
  
    try {
      const token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
  
      const response = await fetch(`${config.apiBaseUrl}/shiftData/bulk`, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
      });
      if (!(await checkResponseStatus(response))) return;
      const result = await response.json();
      
      if (response.ok) {
        alert(`登録成功: ${result.success.length}件, スキップ: ${result.skipped.length}件`);
        setShowBulkRegister(false);
        onCancel(); // 全てのモーダルを閉じる
      } else {
        alert(`登録に失敗しました: ${result.message}`);
      }
    } catch (error) {
      console.error('Error during bulk registration:', error);
      alert('登録中にエラーが発生しました。');
    }
  };
  






  ////////////////
  ////HTML描画////
  ////////////////

  return (
    <div className={`modal-overlay ${isOpen ? 'show' : ''}`} onClick={onCancel}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className={`modal-body ${!canEdit ? 'disabled' : ''}`}>
          <h3>{selectedCell && `${formatDate(selectedCell.date)} `} {employees[selectedCell.row]?.name}のシフト編集</h3>
          {!canEdit && lockInfo && (
            <div className="lock-info">
              <p>このシフトは現在 {lockedByName} さんが編集中です。</p>
            </div>
          )}
          {!isTemporary && (
            <div>
              <div>
                <div className="dropdowns-inline">
                  <label className="dropdown-inline">シフトコード:
                    <select value={shiftCode} onChange={handleShiftCodeChange}>
                      {shifts.map((shift) => (
                        <option key={shift.shift_code} value={shift.shift_code}>
                          {shift.shift_code} ({shift.default_start_time} - {shift.default_end_time})
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div>
                <div className="radio-buttons-inline">
                  <label>時間調整:</label>
                  <label className="checkbox-inline">
                    無し
                    <input
                      type="radio"
                      name="timeAdjustment"
                      value="0"
                      checked={timeAdjustment === 0}
                      onChange={() => setTimeAdjustment(0)}
                    />
                  </label>
                  <label className="checkbox-inline">
                    ＋
                    <input
                      type="radio"
                      name="timeAdjustment"
                      value="1"
                      checked={timeAdjustment === 1}
                      onChange={() => setTimeAdjustment(1)}
                    />
                  </label>
                  <label className="checkbox-inline">
                    －
                    <input
                      type="radio"
                      name="timeAdjustment"
                      value="-1"
                      checked={timeAdjustment === -1}
                      onChange={() => setTimeAdjustment(-1)}
                    />
                  </label>
                </div>
              </div>
              <div>
                <label className="dropdowns-inline">
                  シフトコード2:
                  <select value={secoundShiftCode} onChange={(e) => setSecoundShiftCode(e.target.value)}>
                    <option value="">選択してください</option>
                    {shifts.map((shift) => (
                      <option key={shift.shift_code} value={shift.shift_code}>
                        {shift.shift_code} ({shift.default_start_time} - {shift.default_end_time})
                      </option>
                    ))}
                  </select>
                </label>
              </div>


            </div>
          )}

          {isTemporary && (
            <div>
              <div>
                <label className="dropdowns-inline">
                  開始時間:
                  <input
                    className="timeinput"
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </label>
              </div>
              <div>
                <label className="dropdowns-inline">
                  終了時間:
                  <input
                    className="timeinput"
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </label>
              </div>
            </div>
          )}

          <div>
            <label className="dropdowns-inline">
              シフト状態:
              <select className="dropdown-inline" value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="未承認">未承認</option>
                <option value="承認済み">承認済み</option>
              </select>
            </label>
          </div>
          <div>
            <label>
              備考:
              <textarea value={note} onChange={(e) => setNote(e.target.value)} rows="3" />
            </label>
          </div>


          <label className="checkbox-label">
            臨時シフト:
            <input
              type="checkbox"
              checked={isTemporary}
              onChange={(e) => setIsTemporary(e.target.checked)}
            />
          </label>
          <label className="checkbox-label">
            色指定:
            <input
              type="checkbox"
              checked={isColor}
              onChange={(e) => setIsColor(e.target.checked)}
            />
          </label>
          {isColor && (
            <div>
              <label className="color-label">
                シフトカラー 1:
                <select value={selectColor1} onChange={(e) => {
                  const selected = e.target.value;
                  setSelectColor1(selected);

                  // 任意以外の場合、対応する色をセット
                  if (selected !== "任意") {
                    setShiftColor(defaultColors[selected]);
                  }
                }}>
                  <option value="朝">朝</option>
                  <option value="昼">昼</option>
                  <option value="夕">夕</option>
                  <option value="夜">夜</option>
                  <option value="福生">福生</option>
                  <option value="任意">任意</option>
                </select>
                {selectColor1 === "任意" && (
                  <input
                    className="color-select"
                    type="color"
                    value={shiftColor || "#ffffff"} // null の場合は白色
                    onChange={(e) => setShiftColor(e.target.value)}
                  />
                )}
              </label>

              <label className="checkbox-label">
                シフトカラー 2を設定する
                <input
                  type="checkbox"
                  checked={isSecondColorEnabled}
                  onChange={handleSecondColorToggle}
                />
              </label>

              {isSecondColorEnabled && (
                <label className="color-label">
                  シフトカラー 2:
                  <select value={selectColor2} onChange={(e) => {
                    const selected = e.target.value;
                    setSelectColor2(selected);

                    // 任意以外の場合、対応する色をセット
                    if (selected !== "任意") {
                      setShiftColor2(defaultColors[selected]);
                    }
                  }}>
                    <option value="朝">朝</option>
                    <option value="昼">昼</option>
                    <option value="夕">夕</option>
                    <option value="夜">夜</option>
                    <option value="福生">福生</option>
                    <option value="任意">任意</option>
                  </select>
                  {selectColor2 === "任意" && (
                    <input
                      className="color-select"
                      type="color"
                      value={shiftColor || "#ffffff"} // null の場合は白色
                      onChange={(e) => setShiftColor2(e.target.value)}
                    />
                  )}
                </label>
              )}
            </div>
          )}

          <div>
            <div className="modal-actions">
              <button onClick={handleCopy}>コピー</button>
              <button onClick={handlePaste}>貼り付け</button>
            </div>
            <div className="modal-actions">
              {shiftData[`${employees[selectedCell.row].id}-${selectedCell.date}`] && (
                <button className="button-del-l" onClick={handleOpenDeleteModal}>
                  削除
                </button>
              )}
              <button onClick={() => setShowBulkRegister(true)}>まとめて登録</button>
              <button onClick={handleSave}>保存</button>
              <button className="button-del" onClick={onCancel}>キャンセル</button>
            </div>
          </div>
        </div>
      </div>

      {/* 一括登録用モーダル */}
      {showBulkRegister && (
        <div className="modal-overlay" onClick={() => setShowBulkRegister(false)}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} // クリックイベントの伝播を防ぐ
          >
            <h3>日付を選択してください</h3>
            <DatePicker
              inline
              locale="ja" // 日本語ロケールを適用
              highlightDates={bulkDates}
              selected={bulkDates[0]}
              onChange={toggleDateSelection}
              dayClassName={(date) =>
                bulkDates.some((d) => d.toISOString().split('T')[0] === date.toISOString().split('T')[0])
                  ? 'selected-day'
                  : undefined
              }
            />

            <div>
              <button onClick={handleBulkRegister}>登録</button>
              <button onClick={() => setShowBulkRegister(false)}>キャンセル</button>
            </div>
          </div>
        </div>
      )}


      {showDeleteModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>確認</h3>
            <p>このシフトを削除しますか？</p>
            <div className="modal-actions">
              <button className="button-del-l" onClick={deleteShift}>削除</button>
              <button onClick={handleCloseDeleteModal}>いいえ</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default ShiftModal;
