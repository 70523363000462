// UpdateModal.js
import React, { useState, useEffect } from 'react';
import '../styles/UpdateModal.css';

const UpdateModal = ({ isOpen, onClose }) => {
  const [updateContent, setUpdateContent] = useState('');
  const [lastModified, setLastModified] = useState(null); // 更新日時を保存

  useEffect(() => {
    if (isOpen) {
      fetch('/update.txt')
        .then((response) => {
          // 最終更新日時を取得
          const modifiedDate = response.headers.get('Last-Modified');
          if (modifiedDate) {
            setLastModified(new Date(modifiedDate));
          }
          return response.text();
        })
        .then((data) => setUpdateContent(data))
        .catch((error) => {
          console.error('Error fetching update info:', error);
          setUpdateContent('更新情報の取得に失敗しました。');
        });
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="updatemodal-content" onClick={(e) => e.stopPropagation()}>
        <h2>更新情報</h2>
        <div className="updatemodal-text">
        <pre>{updateContent}</pre>
        </div>
        <button onClick={onClose}>閉じる</button>
      </div>
    </div>
  );
};

export const useIsRecentlyUpdated = () => {
  const [isRecentlyUpdated, setIsRecentlyUpdated] = useState(false);

  useEffect(() => {
    fetch('/update.txt')
      .then((response) => {
        const modifiedDate = response.headers.get('Last-Modified');
        if (modifiedDate) {
          const lastModified = new Date(modifiedDate);
          const oneWeekAgo = new Date();
          oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

          // 一週間以内に更新されたか判定
          setIsRecentlyUpdated(lastModified > oneWeekAgo);
        }
      })
      .catch((error) => console.error('Error fetching update info:', error));
  }, []);

  return isRecentlyUpdated;
};


export default UpdateModal;
