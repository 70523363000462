import React, { useEffect, useState } from 'react';
import '../styles/BugInfoModal.css'; // CSSファイルを作成する場合
import config from '../config/config';

const BugInfoModal = ({ isOpen, onClose }) => {
    const [bugList, setBugList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBugList = async () => {
            try {
                const response = await fetch(`${config.apiBaseUrl}/bug-reports/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                console.log(response);
                const data = await response.json();
                setBugList(data);
            } catch (error) {
                console.error('不具合情報の取得に失敗しました:', error);
            } finally {
                setLoading(false);
            }
        };

        if (isOpen) {
            fetchBugList();
        }
    }, [isOpen]);

    if (!isOpen) return null;

    const getStateLabel = (state) => {
        switch (state.toLowerCase()) {
            case 'open':
                return '未対応';
            case 'closed':
                return '対応済み';
            default:
                return '不明';
        }
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <h3>現在確認されている不具合</h3>
                {loading ? (
                    <p>読み込み中...</p>
                ) : (
                    <ul>
                        {bugList.map((bug) => (
                            <li key={bug.id}>
                                <strong className={`bug-state-${bug.state.toLowerCase()}`}>
                                    {getStateLabel(bug.state)}
                                </strong>
                                : {bug.body} (作成日: {new Date(bug.created_at).toLocaleString()})
                            </li>
                        ))}
                    </ul>
                )}
                <button onClick={onClose}>閉じる</button>
            </div>
        </div>
    );
};

export default BugInfoModal;
