import React, { useState } from 'react';
import '../styles/BugReportModal.css';

const BugReportModal = ({ isOpen, onClose, onSubmit }) => {
  const [description, setDescription] = useState('');

  const handleSubmit = () => {
    if (!description.trim()) {
      alert('内容を入力してください。');
      return;
    }
    onSubmit(description);
    setDescription(''); // フォームをリセット
    onClose(); // モーダルを閉じる
  };

  return isOpen ? (
    <div className="modal-overlay" onClick={onClose}>
      <div className="bugreportmodal-content" onClick={(e) => e.stopPropagation()}>
        <h2>要望投稿/不具合報告</h2>
        <textarea
          placeholder="要望や不具合の内容を記載してください..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={5}
        />
        <button onClick={handleSubmit}>送信</button>
        <button onClick={onClose}>キャンセル</button>
      </div>
    </div>
  ) : null;
};

export default BugReportModal;
