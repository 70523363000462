import React, { useState, useEffect } from 'react';
import config from "../config/config";
import '../styles/ActionHistoryTable.css';

const ActionHistoryTable = () => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        userId: '',
        actionType: '',
        targetType: '',
        startDate: '',
        endDate: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [expandedRow, setExpandedRow] = useState(null); // 展開中の行を管理

    const fetchHistory = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const queryParams = new URLSearchParams({
                ...filters,
                page: currentPage,
                limit: itemsPerPage,
            }).toString();

            const response = await fetch(`${config.apiBaseUrl}/action-history?${queryParams}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            if (!response.ok) throw new Error('Failed to fetch history');
            const data = await response.json();
            setHistory(data);
        } catch (error) {
            console.error('Error fetching action history:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchHistory();
    }, [currentPage, itemsPerPage]);

    const handlePageChange = (direction) => {
        if (direction === 'next') {
            setCurrentPage((prev) => prev + 1);
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const handleExpandRow = (rowId) => {
        setExpandedRow(expandedRow === rowId ? null : rowId); // 展開状態をトグル
    };

    const formatDetails = (details) => {
        try {
            const parsed = JSON.parse(details);
            return JSON.stringify(parsed, null, 2); // 整形表示
        } catch {
            return details; // JSONでなければそのまま表示
        }
    };

    return (
        <div className='ActionHistory'>
            <h2>Action History</h2>
            <div>
                <label>User ID:</label>
                <input type="text" name="userId" className='idinput' value={filters.userId} onChange={(e) => setFilters({ ...filters, userId: e.target.value })} />

                <label>Action Type:</label>
                <select name="actionType" value={filters.actionType} onChange={(e) => setFilters({ ...filters, actionType: e.target.value })}>
                    <option value="">全選択</option>
                    <option value="UPDATE">UPDATE</option>
                    <option value="CREATE">CREATE</option>
                    <option value="DELETE">DELETE</option>
                </select>

                <label>Target Type:</label>
                <select name="targetType" value={filters.targetType} onChange={(e) => setFilters({ ...filters, targetType: e.target.value })}>
                    <option value="">全選択</option>
                    <option value="SHIFT">SHIFT</option>
                    <option value="USER">USER</option>
                    <option value="EMPLOYEE">EMPLOYEE</option>
                </select>
                <div>
                    <label>Start Date:</label>
                    <input type="date" name="startDate" value={filters.startDate} onChange={(e) => setFilters({ ...filters, startDate: e.target.value })} />

                    <label>End Date:</label>
                    <input type="date" name="endDate" value={filters.endDate} onChange={(e) => setFilters({ ...filters, endDate: e.target.value })} />
                </div>
                <button onClick={fetchHistory}>Search</button>
            </div>
            <div style={{ margin: '10px 0' }}>
                <label>1ページあたりの表示件数:</label>
                <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>User ID</th>
                                <th>Action Type</th>
                                <th>Target Type</th>
                                <th>Details</th>
                                <th>Action Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history.map((entry) => (
                                <React.Fragment key={entry.id}>
                                    <tr>
                                        <td>{entry.id}</td>
                                        <td>{entry.user_id}</td>
                                        <td>{entry.action_type}</td>
                                        <td>{entry.target_type}</td>
                                        <td>
                                            <button onClick={() => handleExpandRow(entry.id)}>
                                                {expandedRow === entry.id ? '折りたたむ' : '展開'}
                                            </button>
                                        </td>
                                        <td>{new Date(entry.action_time).toLocaleString()}</td>
                                    </tr>
                                    {expandedRow === entry.id && (
                                        <tr className='expandtext'>
                                            <td colSpan="6">
                                                <pre style={{ background: '#f4f4f4', padding: '10px', borderRadius: '5px', overflowX: 'auto', textAlign: 'left' }}>
                                                    {formatDetails(entry.details)}
                                                </pre>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>
                            前へ
                        </button>
                        <span>ページ: {currentPage}</span>
                        <button onClick={() => handlePageChange('next')}>次へ</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ActionHistoryTable;
