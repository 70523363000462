// src/pages/SettingsPage.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import EmployeeSettings from '../components/EmployeeSettings';
import ShiftSymbolSettings from '../components/ShiftSymbolSettings';
import UserSettings from '../components/UserSettings';
import ShiftTableSettings from '../components/ShiftTableSettings';
import PasswordChange from '../components/PasswordChange';
import { Route, Routes } from 'react-router-dom';
import '../styles/SettingPage.css';
import ActionHistoryTable from '../components/ActionHistoryTable';
import LoginHistoryTable from '../components/LoginHistoryTable';

const SettingsPage = ({ employees, setEmployees, shifts, setShifts, users, setUsers, currentUser }) => {

  return (
    <div className="settings-page">
      <Sidebar currentUser={currentUser} /> {/* サイドバーは固定され、ページ遷移でも消えない */}
      <main className="settings-content">
        {/* 設定ページ内で切り替えを管理 */}
        <Routes>
          <Route path="table" element={<ShiftTableSettings employees={employees} setEmployees={setEmployees} />} />
          <Route path="employees" element={<EmployeeSettings employees={employees} setEmployees={setEmployees} />} />
          <Route path="shifts" element={<ShiftSymbolSettings shifts={shifts} setShifts={setShifts} />} />
          <Route path="users" element={<UserSettings users={users} setUsers={setUsers} employees={employees} />} />
          <Route path="PasswordChange" element={<PasswordChange currentUser={currentUser}/>} />
          <Route path="action-history" element={<ActionHistoryTable />} />
          <Route path="login-history" element={<LoginHistoryTable />} />
          {/* 他の設定項目も追加可能 */}
        </Routes>
      </main>
    </div>
  );
};

export default SettingsPage;
