import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import '../styles/Header.css';
import config from "../config/config";
import { checkResponseStatus } from '../utils/tokenExpiredHandler';
import UpdateModal, { useIsRecentlyUpdated } from './UpdateModal'; // モーダルとフックをインポート
import BugReportModal from './BugReportModal'; // モーダルをインポート
import BugInfoModal from './BugInfoModal'; // モーダルをインポート

const Header = ({ isAuthenticated, setIsAuthenticated, themeColor, currentUser, employees }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false); // メニューの開閉状態
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false); // モーダルの開閉状態
  const isRecentlyUpdated = useIsRecentlyUpdated(); // 更新情報が最近更新されたかどうか
  const [isBugReportModalOpen, setIsBugReportModalOpen] = useState(false); // 不具合報告モーダルの状態

  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/auth/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (!(await checkResponseStatus(response))) return;
      if (response.ok) {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/login');
      } else {
        console.error('サーバー側のログアウトに失敗しました');
      }
    } catch (error) {
      console.error('ログアウトリクエストエラー:', error);
    }
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.user-menu')) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const employeeName = employees?.find(emp => emp.id === currentUser?.employee_id)?.name || '';

  const handleBugReportSubmit = async (description) => {
    const title = `${new Date().toISOString()} - ${currentUser?.username || 'Unknown User'}`; // 固定タイトル
    const labels = [8]; // 固定ラベルID

    try {
      const response = await fetch(`${config.apiBaseUrl}/bug-reports/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title, description, labels }),
      });
console.log(response);
      if (response.ok) {
        alert('不具合報告が送信されました！');
      } else {
        const errorText = await response.text();
        console.error('エラー詳細:', errorText);
        alert('不具合報告の送信に失敗しました。');
      }
    } catch (error) {
      console.error('送信エラー:', error);
      alert('不具合報告の送信中にエラーが発生しました。');
    }
  };

  const [isBugInfoModalOpen, setIsBugInfoModalOpen] = useState(false);

  return (
    <header className="header">
      <div className="header-left">
        <div className="header-logo" onClick={() => navigate('/')}>
          <LogoSVG style={{ fill: themeColor, width: '130px', height: 'auto' }} />
        </div>
        {isAuthenticated && (
          <Link to="/" className="header-back-link">シフト表示へ戻る</Link>
        )}
      </div>
      <div className="header-right">
        {isAuthenticated ? (
          <div className="user-menu" onClick={toggleMenu}>
            <span className="header-username">{currentUser?.username || 'ユーザー名不明'}</span>
            <div className="hamburger-icon" onClick={toggleMenu}>☰</div>
            {menuOpen && (
              <div className="dropdown-menu">
                <Link to="/settings" onClick={closeMenu}>設定</Link>
                <button onClick={() => setIsUpdateModalOpen(true)}>
                  更新情報 {isRecentlyUpdated && <span className="new-update-icon">！</span>}
                </button>
                <button onClick={() => setIsBugReportModalOpen(true)}>
                  要望投稿/不具合報告
                </button>
                <button onClick={() => setIsBugInfoModalOpen(true)}>不具合情報</button>
                <button onClick={() => setIsAuthenticated(false)}>ログアウト</button>
              </div>
            )}
          </div>
        ) : (
          <button onClick={() => setIsUpdateModalOpen(true)} className="update-link">
            更新情報 {isRecentlyUpdated && <span className="new-update-icon">！</span>}
          </button>
        )}
      </div>
      <UpdateModal isOpen={isUpdateModalOpen} onClose={() => setIsUpdateModalOpen(false)} />
      {/* 不具合報告モーダル */}
      <BugReportModal
        isOpen={isBugReportModalOpen}
        onClose={() => setIsBugReportModalOpen(false)}
        onSubmit={handleBugReportSubmit}
      />
      {/* 不具合情報モーダル */}
      <BugInfoModal
        isOpen={isBugInfoModalOpen}
        onClose={() => setIsBugInfoModalOpen(false)}
      />
    </header>
  );
};

export default Header;
